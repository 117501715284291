import type { FormHandle } from '../public/form-handle';
import type { BeforeSubmitEvent, AfterSubmitEvent, BeforeNavigateEvent, AfterNavigateEvent, UploadFileEvent } from '../public/events';
import { FormEvents } from '../public/events';

// Event names for form events that will be proxied as jQuery events

enum CognitoJQueryEvent {
	BeforeSubmit = 'beforeSubmit.cognito',
	AfterSubmit = 'afterSubmit.cognito',
	BeforeNavigate = 'beforeNavigate.cognito',
	AfterNavigate = 'afterNavigate.cognito',
	UploadFile = 'uploadFile.cognito'
}

/**
 * Triggers a custom jQuery event and returns the event object so that it can be inspected
 * @param jQuery The jQuery global/static object
 * @param event The name of the event
 * @param payload The data to send with the event
 */
function fireJQueryEvent(jQuery: JQueryStatic, event: CognitoJQueryEvent, payload: object): JQueryEventObject {
	const customEvent = new jQuery.Event(event);
	jQuery(document).trigger(customEvent as any, [payload]);
	return customEvent;
}

/**
 * Proxy supported form handle events to the given jQuery global object
 * @param form The form handle from which to proxy events
 * @param jQuery The jQuery global static object to proxy events to
 */
export function proxyEventsToJQuery(form: FormHandle, jQuery: JQueryStatic, eventsArePreventable: boolean): void {
	form.on('*', (e: BeforeSubmitEvent | AfterSubmitEvent | BeforeNavigateEvent | AfterNavigateEvent | UploadFileEvent) => {
		// Old versions of jQuery do not support creating custom events
		if (typeof jQuery.Event === 'function') {
			if (e.type === FormEvents.BeforeSubmit) {
				const jqEvent = fireJQueryEvent(jQuery, CognitoJQueryEvent.BeforeSubmit, e.data);
				if (eventsArePreventable && jqEvent.isDefaultPrevented()) {
					e.preventDefault();
				}
			}
			else if (e.type === FormEvents.AfterSubmit) {
				fireJQueryEvent(jQuery, CognitoJQueryEvent.AfterSubmit, e.data);
			}
			else if (e.type === FormEvents.BeforeNavigate) {
				const jqEvent = fireJQueryEvent(jQuery, CognitoJQueryEvent.BeforeNavigate, e.data);
				if (eventsArePreventable && jqEvent.isDefaultPrevented()) {
					e.preventDefault();
				}
			}
			else if (e.type === FormEvents.AfterNavigate) {
				fireJQueryEvent(jQuery, CognitoJQueryEvent.AfterNavigate, e.data);
			}
			else if (e.type === FormEvents.UploadFile) {
				fireJQueryEvent(jQuery, CognitoJQueryEvent.UploadFile, e.data);
			}
		}
	});
}
